// import { Image } from "@chakra-ui/react";
// import banner from "../Ads/ad_geek.png";
// import banner from "../Ads/citygold.jpg";

import { Image, Icon, Box } from "@chakra-ui/react";
// import { Icon, Box } from "@chakra-ui/react";

import { FaHeart } from "react-icons/fa";
import AdSense from "react-adsense";

export default function SponsorCard() {
  return (
    <Box
      p={0}
      mt={[4, 100, 100, 4]}
      // maxW={1350}
      display="flex"
      flexDirection={"column"}
      alignItems="center"
    >
      {/* <a href="https://michalmajer.produktyfinansowe.pl/e/lead/2192/label=mainpage?source=lt"><Image  src={banner} /> </a> */}

      <AdSense.Google
        client="ca-pub-3356554675685868"
        slot="7864227744"
        format=""
        responsive="true"
        // format="auto"
        // responsive="true"
        style={{ display: "block", width: "100%", height: 150 }}
      // format="auto"
      // responsive="true"
      // layoutKey="-gw-1+2a-9x+5c"
      />

      <Box
        display="flex"
        alignItems="center"
        as="a"
        cursor={'pointer'}
        // href={
        //   "mailto:michal.majer@hotmail.com?subject=Reklama na stronie kalkulatorb2b.pl&body=Dzień dobry, jestem zainteresowany reklamą na stronie kalkulatorb2b.pl. Proszę o szczegóły. Moja strona / produkt to: "
        // }
        href="https://easl.ink/pygJT"
        // data-tally-open="n0Lze9" data-tally-layout="modal" data-tally-width="750" data-tally-hide-title="1" data-tally-overlay="1" data-tally-emoji-text="👋" data-tally-emoji-animation="wave"
        target="_blank"
        rel="noopener noreferrer"
        bg="gray.50"
        borderWidth="1px"
        borderColor="gray.200"
        px="1em"
        mt={2}
        minH="36px"
        // rounded="md"
        fontSize="sm"
        color="gray.800"
        outline="0"
        transition="all 0.3s"
        _hover={{
          bg: "gray.100",
          borderColor: "gray.300",
        }}
        _active={{
          borderColor: "gray.200",
        }}
        _focus={{
          boxShadow: "outline",
        }}
      ml={5}
      >
        {/* <Icon as={FaHeart} w="4" h="4" color="red.500" mr="2" /> */}

        <Image src={'./cover.png'} w="64"/>

        <Box as="strong" pl={4} lineHeight="inherit" fontWeight="semibold">
          Poradnik Auto w Firmie (e-book) + kalkulator już dostępny! 🚗
        </Box>

        {/*
        <Box as="strong" lineHeight="inherit" fontWeight="semibold">
          🚗 Wypełnij ankietę i otrzymaj rabat. Dziękuję!
        </Box>
        */}
      </Box>

    </Box>
  );
}
